import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'harvesting',
    loadChildren: () => import('./harvesting/harvesting.module').then( m => m.HarvestingPageModule)
  },
  {
    path: 'forgetpassword',
    loadChildren: () => import('./forgetpassword/forgetpassword.module').then( m => m.ForgetpasswordPageModule)
  },
  {
    path: 'resetpassword/:id',
    loadChildren: () => import('./resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  },
  {
    path: 'harvestingrecord',
    loadChildren: () => import('./harvestingrecord/harvestingrecord.module').then( m => m.HarvestingrecordPageModule)
  },  {
    path: 'morningattendance',
    loadChildren: () => import('./morningattendance/morningattendance.module').then( m => m.MorningattendancePageModule)
  },
  {
    path: 'attendancelist',
    loadChildren: () => import('./attendancelist/attendancelist.module').then( m => m.AttendancelistPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
